import React, { useEffect } from 'react';
import { formatTime } from "../../../helpers/dates";
import { Link } from "react-router-dom";
import { MatchFormatShortNames } from "../../pages/Home/constants";
import liveIcon from '../../../Assets/Ellipse 6.png';
import { doScrollToTop } from "../../../helpers/general";
import LiveAnimatedRedDot from '../LiveAnimatedRedDot';

export const MatchBox = ({ match, customClass: classname, type }) => {
  useEffect(() => {
    const scrollDiv = document.querySelector('.series_auto_scroll');
    scrollDiv && scrollDiv.scrollIntoView && scrollDiv.scrollIntoView({ block: 'start', behavior: "smooth" });
  }, [])
  const matchStatus = match.status === 'Scheduled' || match.status === 'Live'
  return (
    <div
      data-matchid={match?.match_id}
      data-testid={`LiveMatchBox__Container__${match?.match_id}`}
      className="md:hover:bg-gray-100 rounded-xl transition-colors border md:border-none border-[#E3E3E4]"
    >
      <div className={`p-4 ${type === 'series' ? 'items-center flex' : ''} ${matchStatus && 'series_auto_scroll'}`} style={{ scrollMargin: '90px' }}>
        <div className='w-full md:w-auto'>
          {type === 'series' ? (
            <div className="md:flex justify-between">
              <Link
                to={`/series/${match?.slug}/matches/${match.slug}/${match.status_str === "Scheduled" ? "matchinfo" : match.status_str === "Live" ? "commentary" : match.status_str === "Cancelled" ? 'matchinfo' : "scorecard"}`}
                onClick={doScrollToTop}
              >
                <div>
                  <div className="flex justify-between md:justify-normal items-center w-full md:w-[550px] md:max-w-[900px] text-left">
                    <div>
                      <div className='flex mt-1'>
                        <img
                          src={match?.team_a?.logo}
                          alt={match?.team_a?.name}
                          className={`${classname} w-5 h-5 mr-1`}
                        />
                        <p className={`${classname} text-sm mx-1`}>
                          {match?.team_a?.name}
                        </p>
                      </div>
                      <p className="mx-1 text-xs text-[#787878] mr-2 mt-1 hidden">vs</p>
                      <div className='flex mt-1'>
                        <img
                          src={match?.team_b?.logo}
                          alt={match?.team_b?.name}
                          className={`${classname} w-5 h-5 mr-1`}
                        />
                        <p className={`${classname} text-sm mx-1`}>
                          {match?.team_b?.name}
                        </p>
                      </div>
                    </div>
                    <p className={`flex md:hidden ${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium items-center rounded-full ml-1 px-2 whitespace-nowrap mt-1`}>
                      {MatchFormatShortNames[match?.match_format] || match?.match_format}
                    </p>
                  </div>
                  <p className="pt-1 md:w-[400px] md:max-w-[400px] text-[#787878] text-xs font-medium">
                    {match?.venue}
                  </p>
                  {match?.status_str === 'Live' || match?.status_str === 'Completed' ? (
                    <p className={`${match?.status_str === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold md:w-[400px] md:max-w-[400px]`}
                      dangerouslySetInnerHTML={{ __html: match?.status_note }}
                    >
                    </p>
                  ) : null}
                </div>
              </Link>
              <div className="text-left md:mt-0 mt-2 md:ml-3">
                <p className={`hidden md:inline-flex ${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium items-center rounded-full px-2 whitespace-nowrap mt-1`}>
                  {MatchFormatShortNames[match?.match_format] || match?.match_format}
                </p>
                <p className={`${classname} min-h-5 min-w-full text-sm font-bold flex items-center`}>
                  {formatTime(match?.date_start)?.localTime}
                  <span className="flex items-center ml-2">
                    {match?.status === 'Live' && (
                      <>
                        {/* <img src={liveIcon} alt="live" /> */}
                        <LiveAnimatedRedDot height='5px' width='5px' className='me-1' />
                        <span className={`${classname} text-[10px] text-green-600`}>
                          {match?.status}
                        </span>
                      </>
                    )}
                  </span>
                </p>
                <p className="text-[10px] text-[#787878]">
                  <span className={`${classname} min-h-[16px] min-w-[100px]`}>
                    {formatTime(match?.date_start)?.utcTime + " GMT"} / {" "}
                  </span>
                  <span className={`${classname} min-h-[16px] min-w-[100px]`}>
                    {formatTime(match?.date_start)?.localTime + " LOCAL"}
                  </span>
                </p>
              </div>
            </div>
          ) : match.status === 'Scheduled' ? (
            <Link
              to={`/series/${match?.tournament_slug}/matches/${match?.slug}/${match.status === "Scheduled" ? "matchinfo" : match.status === "Live" ? "commentary" : match.status === "Cancelled" ? 'matchinfo' : "scorecard"}`}
              onClick={doScrollToTop}
            >
              <div className='md:grid grid-cols-9'>
                <div className='flex md:block justify-between items-center col-span-2'>
                  <Link to={`/series/${match?.tournament_slug}/schedule`} >
                    <p className={`${classname} text-[#000000] opacity-80 min-h-[20px] text-sm font-medium`}>
                      {match?.tournament_name}
                    </p>
                  </Link>
                  <div className='md:hidden block'>
                    <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap`}>
                      {MatchFormatShortNames[match?.format] || match?.format}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between md:items-start items-center col-span-7 md:grid grid-cols-3'>
                  <div className='md:mt-0 mt-2 md:w-[325px] xl:w-[550px] col-span-2'>
                    <div className="md:flex items-center md:w-[320px] md:max-w-[320px] xl:w-[540px] xl:max-w-[540px] text-left">
                      <div className=' w-[178px] md:w-auto'>
                        <div className='flex'>
                          <div className='flex min-w-20 md:min-w-0 md:mt-1'>
                            <img
                              src={match?.teama?.logo}
                              alt={match?.teama?.name}
                              className={`${classname} w-5 h-5 md:mr-1`}
                            />
                            <p className={`${classname} text-sm mx-1 truncate`}>
                              {match.status === 'Scheduled' ? match?.teama?.name : match?.teama?.short_name}
                            </p>
                          </div>
                          <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                            {match?.teama?.scores?.replace(/\/10/g, '')}
                            <span className='text-[9px] pl-0.5 text-[#808080]'>
                              {match?.teama?.overs && `(${match?.teama?.overs})`}
                            </span>
                          </div>
                        </div>
                        <div className='flex mt-2 md:mt-1'>
                          <div className='flex min-w-20 md:min-w-0'>
                            <img
                              src={match?.teamb?.logo}
                              alt={match?.teamb?.name}
                              className={`${classname} w-5 h-5 md:mr-1`}
                            />
                            <p className={`${classname} text-sm mx-1 truncate`}>
                              {match.status === 'Scheduled' ? match?.teamb?.name : match?.teamb?.short_name}
                            </p>
                          </div>
                          <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                            {match?.teamb?.scores?.replace(/\/10/g, '')}
                            <span className='text-[9px] pl-0.5 text-[#808080]'>
                              {match?.teamb?.overs && `(${match?.teamb?.overs})`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] hidden md:block`}>
                      {match?.subtitle}, {match?.venue}
                    </p>
                    <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold hidden md:block`}
                      dangerouslySetInnerHTML={{ __html: match?.status_note }}
                    >
                    </p>
                  </div>
                  <div className={`md:w-max md:mx-auto md:mr-4 md:mt-0 col-span-1 mt-2 ${match?.status === 'Live' ? "md:block hidden" : ""}`}>
                    <div className='hidden md:inline-block'>
                      <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-fullma px-2 whitespace-nowrap`}>
                        {MatchFormatShortNames[match?.format] || match?.format}
                      </p>
                    </div>
                    <div className={`${classname} min-h-5 min-w-full text-sm font-bold flex md:justify-start justify-end md:items-center`}>
                      {formatTime(match?.date_start_utc)?.localTime}
                      <div>
                        <span className="flex items-center md:ml-2">
                          {match?.status === 'Live' && (
                            <>
                              {/* <img src={liveIcon} alt="live" /> */}
                              <LiveAnimatedRedDot height='5px' width='5px' className='me-1' />
                              <span className={`${classname} text-[10px] text-green-600`}>
                                {match?.status}
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="text-[10px] text-[#787878]">
                      <div className={`${classname} min-h-[16px] text-end md:text-start min-w-[100px]`}>
                        {formatTime(match?.date_start_utc)?.utcTime + " GMT"}
                      </div>
                    </div>
                  </div>

                </div>
                <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] md:hidden`}>
                  {match?.subtitle}, {match?.venue}
                </p>
                <div className='flex justify-between items-center md:hidden'>
                  <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold `}
                    dangerouslySetInnerHTML={{ __html: match?.status_note }}
                  >
                  </p>
                  <div className='md:hidden'>
                    <span className="">
                      {match?.status === 'Live' && (
                        <div className='flex items-center gap-0.5'>
                          {/* <img src={liveIcon} alt="live" /> */}
                          <LiveAnimatedRedDot height='5px' width='5px' className='me-1' />
                          <div className={`${classname} text-[10px] leading-[10px] text-green-600`}>
                            {match?.status}
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          )
            : (
              <Link
                to={`/series/${match?.tournament_slug}/matches/${match?.slug}/${match.status === "Scheduled" ? "matchinfo" : match.status === "Live" ? "commentary" : match.status === "Cancelled" ? 'matchinfo' : "scorecard"}`}
                onClick={doScrollToTop}
              >
                <div className='md:grid grid-cols-9'>
                  <div className='flex md:block justify-between items-center col-span-2'>
                    <Link to={`/series/${match?.tournament_slug}/schedule`} >
                      <p className={`${classname} text-[#000000] opacity-80 min-h-[20px] text-sm font-medium`}>
                        {match?.tournament_name}
                      </p>
                    </Link>
                    <div className='md:hidden block'>
                      <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap`}>
                        {MatchFormatShortNames[match?.format] || match?.format}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between md:items-start items-center col-span-7 md:grid grid-cols-3'>
                    <div className='md:mt-0 mt-2 md:w-[325px] xl:w-[550px] col-span-2'>
                      <div className="md:flex items-center md:w-[320px] md:max-w-[320px] xl:w-[540px] xl:max-w-[540px] text-left">
                        <div className='md:flex md:flex-wrap w-[178px] md:w-auto'>
                          <div className='flex'>
                            <div className='flex min-w-20 md:min-w-0 md:mt-1'>
                              <img
                                src={match?.teama?.logo}
                                alt={match?.teama?.name}
                                className={`${classname} w-5 h-5 md:mr-1`}
                              />
                              <p className={`${classname} text-sm mx-1 truncate`}>
                                {match.status === 'Scheduled' ? match?.teama?.name : match?.teama?.short_name}
                              </p>
                            </div>
                            <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                              {match?.teama?.scores?.replace(/\/10/g, '')}
                              <span className='text-[9px] pl-0.5 text-[#808080]'>
                                {match?.teama?.overs && `(${match?.teama?.overs})`}
                              </span>
                            </div>
                          </div>
                          <p className="mx-1 text-xs text-[#787878] hidden md:block md:mt-1">vs</p>
                          <div className='flex mt-2 md:mt-1'>
                            <div className='flex min-w-20 md:min-w-0'>
                              <img
                                src={match?.teamb?.logo}
                                alt={match?.teamb?.name}
                                className={`${classname} w-5 h-5 md:mr-1`}
                              />
                              <p className={`${classname} text-sm mx-1 truncate`}>
                                {match.status === 'Scheduled' ? match?.teamb?.name : match?.teamb?.short_name}
                              </p>
                            </div>
                            <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                              {match?.teamb?.scores?.replace(/\/10/g, '')}
                              <span className='text-[9px] pl-0.5 text-[#808080]'>
                                {match?.teamb?.overs && `(${match?.teamb?.overs})`}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='hidden md:block'>
                          <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap`}>
                            {MatchFormatShortNames[match?.format] || match?.format}
                          </p>
                        </div>
                      </div>
                      <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] hidden md:block`}>
                        {match?.subtitle}, {match?.venue}
                      </p>
                      <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold hidden md:block`}
                        dangerouslySetInnerHTML={{ __html: match?.status_note }}
                      >
                      </p>
                    </div>
                    <div className={`md:w-max md:mx-auto md:mr-4 md:mt-0 col-span-1 mt-2 ${match?.status === 'Live' ? "md:block hidden" : ""}`}>
                      <div className={`${classname} min-h-5 min-w-full text-sm font-bold flex md:justify-start justify-end md:items-center`}>
                        {formatTime(match?.date_start_utc)?.localTime}
                        <div>
                          <span className="flex items-center md:ml-2">
                            {match?.status === 'Live' && (
                              <>
                                {/* <img src={liveIcon} alt="live" /> */}
                                <LiveAnimatedRedDot height='5px' width='5px' className='me-1' />
                                <span className={`${classname} text-[10px] text-green-600`}>
                                  {match?.status}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="text-[10px] text-[#787878]">
                        <div className={`${classname} min-h-[16px] text-end md:text-start min-w-[100px]`}>
                          {formatTime(match?.date_start_utc)?.utcTime + " GMT"}
                        </div>
                      </div>
                    </div>

                  </div>
                  <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] md:hidden`}>
                    {match?.subtitle}, {match?.venue}
                  </p>
                  <div className='flex justify-between items-center md:hidden'>
                    <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold `}
                      dangerouslySetInnerHTML={{ __html: match?.status_note }}
                    >
                    </p>
                    <div className='md:hidden'>
                      <span className="">
                        {match?.status === 'Live' && (
                          <div className='flex items-center gap-0.5'>
                            {/* <img src={liveIcon} alt="live" /> */}
                            <LiveAnimatedRedDot height='5px' width='5px' className='me-1' />
                            <div className={`${classname} text-[10px] leading-[10px] text-green-600`}>
                              {match?.status}
                            </div>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};