import React, { useEffect, useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import SEOMeta from '../pages/Home/common/SEOMeta';
import { useAxiosSWR } from '../../../axiosInstance';
import { NewsContainer } from '../NewsV2';
import AppContext, { URL_TYPES } from "src/context";
import { useLocation } from "react-router-dom";
import ArticleCategoryIntroduction from '../ArticleCategoryIntroduction';

export const convertSlugToTitle = (slug) => {
    return slug
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const MoreNews = () => {
    const { category_slug } = useParams();
    const [pageNum, setPageNum] = useState(1);
    const { data, isLoading, error } = useAxiosSWR(`/v2/news?category_slug=${category_slug}&per_page=10&page=${pageNum}`);
    const newsData = data?.data || [];
    const [seoMeta, setSEOMeta] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const showArticleCategoryIntro = searchParams.get('showArticleCategoryIntro') === "true";

    useEffect(() => {
        if (data && data.status === 'success') {
            const pageTitle = convertSlugToTitle(category_slug);
            setSEOMeta({
                title: `Cricketgully - ${pageTitle}`,
                description: `Stay updated with the latest top stories in cricket for category ${pageTitle}.`,
                canonicalURL: `/category/${category_slug}`,
            });
        }
    }, [data, category_slug]);

    const hasNextPage = data?.data?.total_pages && pageNum < data.data.total_pages;
    const handleSeeMore = () => {
        if (hasNextPage) {
            setPageNum((prevPage) => prevPage + 1);
        }
    };

    const appContext = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    const title = convertSlugToTitle(category_slug)

    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.CATEGORY_NEWS,
            path: pathName,
            reqState: {
                data: {
                    title: title,
                }
            },
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName, title]);

    if (isLoading) {
        return <div></div>;
    }
    if (error) {
        return <div></div>;
    }

    return (
        <div className='mt-4'>
            {seoMeta && <SEOMeta data={seoMeta} />}
            {showArticleCategoryIntro ?
                <ArticleCategoryIntroduction category_slug={category_slug} />
                : null
            }
            <NewsContainer
                newsData={newsData}
                title={convertSlugToTitle(category_slug)}
                hasNextPage={hasNextPage}
                handleSeeMore={handleSeeMore}
                isLoading={isLoading}
                hasError={error}
                pageNum={pageNum}
            />
        </div>
    );
};

export default MoreNews;
