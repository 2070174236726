import React, { useCallback, useState, useRef, useMemo, useEffect, useContext } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { formatDate } from "../../../helpers/dates";
import { Link, useLocation } from "react-router-dom";
import { getSanitizedArray } from "../../../helpers/general";
import { MONTH_NAMES } from "../../../helpers/dates";
import arrow from "../../../Assets/arrow.svg";
import Calendar from "react-calendar";
import CalendarIcon from "../../../Assets/calendar_month.svg";
import 'react-calendar/dist/Calendar.css';
import { useHorizontalScroll } from "../../../hooks/useHorizontalScroll";
import liveImg from "../../../Assets/Group 5.svg"
import { MatchFormatShortNames } from "../Home/constants";
import AppContext, { URL_TYPES } from "src/context";
import GoogleAdUnit from "../../common/GoogleAdUnit";
import { getMobileDetect, SlotProps } from "../../../constants";
const SEOMeta = React.lazy(() => import("../Home/common/SEOMeta"));

const Series = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [showCalendar, setShowCalendar] = useState(false);
  const [filterType, setFilterType] = useState("All");

  const { data, error, isLoading, mutate: seriesMutate } = useAxiosSWR(`/v1/series/seriesByMonth?year=${selectedYear}`);
  const series = data?.series || {};
  const monthsList = MONTH_NAMES.map(m => `${m} ${selectedYear}`);
  const seriesTypes = getSanitizedArray(data?.filterKeys);

  const appContext = useContext(AppContext);
  const { pathname: pathName } = useLocation();
  const containerRef = useRef();
  useHorizontalScroll(containerRef, `${selectedYear}_${MONTH_NAMES[selectedMonth]}`);
  useEffect(() => {
    const urlInfoObj = {
      type: URL_TYPES.SERIES_HOME,
      path: pathName,
      reqState: null,
    };
    appContext.updateUrlInfo(urlInfoObj);
  }, [pathName]);

  const hasSeriesTypes = seriesTypes.length > 0;

  const handleYearClick = useCallback((dateString) => {
    const dt = new Date(dateString);
    setSelectedYear(dt.getFullYear());
    setSelectedMonth(0);
    setShowCalendar(false);
  }, []);

  const filterSeries = useCallback((seriesList, filter) => {
    const arr = getSanitizedArray(seriesList);
    if (filter === "All") {
      return arr;
    }
    return arr.filter((series) =>
      series.game_format === filter || series.category === filter
    );
  }, []);

  const filteredSeriesTypes = useMemo(() => {
    const month = monthsList[selectedMonth];
    const seriesData = series[month];
    const uniqueGameFormats = seriesData ? [...new Set(seriesData.map(s => s.game_format))] : [];
    return uniqueGameFormats.length > 0 ? ["All", ...uniqueGameFormats] : [];
  }, [selectedMonth, series, monthsList]);
  useEffect(() => {
    if (!filteredSeriesTypes.includes(filterType)) {
      setFilterType("All");
    }
  }, [filteredSeriesTypes, filterType]);
  const handleFilterChange = useCallback((filter) => {
    setFilterType(filter);
  }, []);

  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile());

  if (isLoading) {
    return <div></div>;
  }
  if (error) {
    return (
      <div>
        <div></div>
        <button></button>
      </div>
    );
  }
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div className="">
      <React.Suspense><SEOMeta slug="series" /></React.Suspense>
      <div className="">
        <div className="md:bg-[#FFFFFF] bg-[#312AB7] md:py-6 md:border md:rounded-[20px] md:mx-0 md:mb-4 md:my-4">
          <div className="md:block hidden">
            <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px]`}>
              <span className="custom-border-left mr-3 md:mr-6"></span>
              <h1 className='text-[16px] md:text-2xl'>Series</h1>
              <span className="custom-border-right ml-3 md:ml-6"></span>
            </div>
          </div>
          {hasSeriesTypes && (
            <div className="mx-auto flex justify-center">
              <FilterButtons filterType={filterType} handleFilterChange={handleFilterChange} seriesTypes={filteredSeriesTypes} />
            </div>
          )}
        </div>
        <div className="md:bg-white md:rounded-2xl md:p-6 md:border relative">
          <div ref={containerRef} className="flex justify-between md:border md:rounded-2xl p-2 overflow-x-auto hideScrollbar sm:gap-0 gap-3 md:bg-transparent bg-[#312AB7] md:mb-6">
            {MONTH_NAMES.map((name, i) => (
              <div
                key={i}
                data-testid={`${selectedYear}_${name}`}  // Ensure correct association with selectedTestId
                className={`text-center text-xs font-semibold cursor-pointer rounded-lg p-2 ${selectedMonth === i ? "bg-[#F5F9FF]" : "md:text-[#858585] text-[#FFFFFF] md:opacity-90 opacity-70"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedMonth(i);
                }}
              >
                <p>{selectedYear}</p>
                <p>{name}</p>
              </div>
            ))}
            <img src={arrow} alt="arrow" className="cursor-pointer" onClick={() => {
              setSelectedYear((prev) => prev + 1);
              setSelectedMonth(0);
            }} />
            <img src={CalendarIcon} alt="icon" className="cursor-pointer sticky -right-2 md:bg-white bg-[#312AB7] border-l sm:px-4 px-2 " onClick={toggleCalendar} />
            {showCalendar && (
              <div className="absolute bg-white right-5 z-40 top-24">
                <Calendar
                  view="decade"
                  value={new Date(`${selectedYear}-01-01`)}
                  onClickYear={handleYearClick}
                  next2Label={null}
                  prev2Label={null}
                />
              </div>
            )}
          </div>
          {isMobile &&
            <div className="-x-auto hideScrollbar flex items-center justify-center">
              <GoogleAdUnit insCustomClass='mt-4' slotProps={SlotProps.Mobile_Below_Match_Carousel} />
            </div>
          }
          <div className="md:mx-0 my-4 mx-[6px] border md:rounded-none rounded-xl md:border-none border-[#DAE6F8]">
            <div className="bg-[#D8E7FF] text-[#312AB7] md:rounded-xl rounded-t-xl grid grid-cols-8 border border-[#DAE6F8]">
              <div className="px-4 py-2 col-span-2 md:text-base text-xs font-semibold">MONTH</div>
              <div className="px-4 py-2 col-span-6 md:text-base text-xs font-semibold">SERIES</div>
            </div>
            <div>
              {filteredSeriesTypes.length > 0 && <div className="col-span-6">
                {monthsList.map((month, index) => {
                  const filteredSeries = filterSeries(series[month], filterType);
                  return (
                    <div
                      key={index}
                      id={month.replace(" ", "_")}
                      className="grid grid-cols-8 md:bg-transparent bg-white md:p-0 px-3 md:rounded-none rounded-b-xl"
                      style={{ display: selectedMonth === index ? 'grid' : 'none' }}
                    >
                      <div className="px-3 bg-[#EEF0F3] py-2 lg:text-lg md:text-base rounded-lg font-semibold col-span-2 md:my-2 my-4">
                        {MONTH_NAMES[selectedMonth] || ""} {selectedYear}
                      </div>
                      <div className="md:px-2 pl-2 py-2 col-span-6">
                        {filteredSeries.map((seriesData, seriesIndex) => (
                          <Link to={`/series/${seriesData.slug}/schedule`} key={seriesIndex}>
                            <div className={`py-3 ${filteredSeries.length > 1 && seriesIndex !== filteredSeries.length - 1 ? `border-b border-gray-300` : ""}`}>
                              <p className="font-semibold lg:text-lg sm:text-sm">{seriesData.title}</p>
                              <p className="text-[#91949B] text-xs">
                                {formatDate(seriesData.date_start, false)} {" - "} {formatDate(seriesData.date_end, false)}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>}
              <div className="grid grid-cols-8 md:mb-4">
                {filteredSeriesTypes?.length === 0 &&
                  <>
                    <div className="px-3 bg-[#EEF0F3] py-2 lg:text-lg md:text-base rounded-lg font-semibold col-span-2 my-2">
                      {MONTH_NAMES[selectedMonth] || ""} {selectedYear}
                    </div>
                    <div className="px-2 py-2 col-span-6">
                      <div className="w-[200px] md:h-[157px] h-[90px] flex flex-col justify-center items-center">
                        <img src={liveImg} alt="No matches" className="md:w-[80px] w-[50px] md:h-[80px] h-[50px]" />
                        <p className="md:text-[14px] text-xs text-[#333333] pt-1">
                          No series for this month
                        </p>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterButtons = ({ filterType, handleFilterChange, seriesTypes }) => {
  const containerRef = useRef();
  useHorizontalScroll(containerRef, filterType);

  // If no seriesTypes are specified, don't render the "All" tab
  if (!seriesTypes.length) {
    return null;
  }

  return (
    <div ref={containerRef} className="overflow-x-auto hideScrollbar md:mt-4 max-w-max flex md:gap-2">
      {seriesTypes?.map((type) => (
        <button
          key={type}
          data-testid={type}
          className={`md:rounded-[14px] py-2 px-6 font-medium md:text-base text-[14px] text-nowrap ${filterType === type
            ? "text-[#FFFFFF] bg-[#312AB7] md:rounded-[14px] md:px-7 md:border-none border-b-[3px] border-[#FFFFFF]"
            : "md:bg-[#FFFFFF] bg-[#312AB7] md:border-2 md:text-[#000000] p-1 md:opacity-90 opacity-70 text-[#FFFFFF] md:rounded-[14px]"}`}
          onClick={() => handleFilterChange(type)}
        >
          {MatchFormatShortNames[type] || type}
        </button>
      ))}
    </div>
  );
};
export default Series;
